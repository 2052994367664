import PropTypes from "prop-types";
import { createElement, lazy, Suspense, useMemo } from "react";

/**
 * This component presents two opportunities for infinite loops:
 * 1. Value for `story` prop is `LazyStory`. This causes LazyStory to load itself forever.
 * 2. LazyStory does not wrap children in Suspense boundary
 */
function LazyStory({ children, story, ...props }) {
  if (story === "LazyStory") {
    throw new Error("Panic");
  }
  const ChildrensStory = useMemo(
    () => lazy(() => import(`components/${story}`)),
    [story]
  );
  return createElement(
    Suspense, // !!! This suspsense boundary is a must, must, must !!!
    { fallback: null },
    // ChildrensStory will unmount itself repeatedly because it is a lazy component.
    // The suspense parent prevents an infinite loop
    createElement(ChildrensStory, props, children)
  );
}

LazyStory.propTypes = {
  children: PropTypes.node,
  story: PropTypes.string.isRequired,
};

export default LazyStory;
