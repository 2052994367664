import React from "react";
import { Container, Text } from "./styles";

const BlogMetadata = ({ fontColor, background, author, timestamp }) => {
  return (
    <Container background_color={background} font_color={fontColor}>
      <Text>{author}</Text>
      <Text>{timestamp}</Text>
    </Container>
  );
};

export default BlogMetadata;
