import PropTypes from "prop-types";
import React, { createElement, Fragment } from "react";
import { Element } from "react-scroll";

import { BrowserFeature } from "components/BrowserFeature";
import Layout from "components/Layout";
import LazyStory from "components/LazyStory";
import BlogMetadata from "components/BlogMetadata";

export default function StoryComposition({
  pageContext: { stories, storyIds, title, author, timestamp },
  uri,
  ...rest
}) {
  const lazyStories = storyIds.map((storyId) => {
    let [story, storyProps] = stories[storyId];
    if (!story) return null;
    return (
      <Fragment key={storyId}>
        <BrowserFeature>
          <Element name={storyId} />
        </BrowserFeature>
        {createElement(LazyStory, {
          ...storyProps,
          story,
        })}
      </Fragment>
    );
  });

  // auto generate blog metadata on blog pages
  if (uri.includes("/blog/")) {
    const targetBackgroundColor = stories[storyIds[1]][1]?.background ?? "#fff";
    const targetFontColor = stories[storyIds[1]][1]?.fontColor ?? "#392E2B";
    const storyId = `BlogMetadata-${author}-${timestamp}-${title}-${uri}`;

    const storyProps = {
      author,
      timestamp,
      background: targetBackgroundColor,
      fontColor: targetFontColor,
    };

    const BlogMetaDataLazyStory = (
      <Fragment key={storyId}>
        <BrowserFeature>
          <Element name={storyId} />
        </BrowserFeature>
        <BlogMetadata {...storyProps} />
      </Fragment>
    );

    lazyStories.splice(1, 0, BlogMetaDataLazyStory);
  }
  return <Layout title={title}>{lazyStories}</Layout>;
}

StoryComposition.propTypes = {
  pageContext: PropTypes.shape({
    stories: PropTypes.objectOf(
      PropTypes.arrayOf(function (propValue) {
        if (
          !(
            typeof propValue[0] === "string" &&
            propValue[1] !== null &&
            typeof propValue[1] === "object"
          )
        ) {
          return new Error(
            `Invalid story shape. Expected a tuple of name and props, e.g. ["FooComponent", { bar: "baz" }]. Got:`,
            propValue
          );
        }
      })
    ).isRequired,
    storyIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
