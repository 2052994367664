import styled from "styled-components";

export const Text = styled.text`
  font-family: Gotham, sans-serif;
  font-size: clamp(0.8125rem, 0.6876rem + 0.4163vw, 1.125rem);
  line-height: 125%;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 3.75em;
  background: ${({ background_color }) => background_color};
  color: ${({ font_color }) => font_color};
  padding: 0 3em;
`;
